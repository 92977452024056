
import { defineComponent, onMounted, ref } from "vue";
import AdminCalendar from "@/components/admin-calendar/index.vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useAbility } from "@casl/vue";

export default defineComponent({
  name: "user-calendar",
  components: {
    AdminCalendar
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const userId = ref<number | null>(null);
    onMounted(() => {
      const parsedUserId = parseInt(route.params?.id as string);
      if (
        Number.isNaN(parsedUserId) ||
        useAbility().cannot("can_view_other_users_calendar", "all")
      ) {
        router.push("/");
      }
      userId.value = parsedUserId;
    });
    return {
      userId
    };
  }
});
