import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-8 col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminCalendar = _resolveComponent("AdminCalendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.userId)
      ? (_openBlock(), _createBlock(_component_AdminCalendar, {
          key: 0,
          userId: _ctx.userId
        }, null, 8, ["userId"]))
      : _createCommentVNode("", true)
  ]))
}